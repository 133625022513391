
<template>
  <div class="grid grid-cols-12 gap-6 mt-8">
    <AsideModules :course="course" />
    <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
      <!-- END: File Manager Filter -->
      <div v-if="students.data" class="intro-y md:mt-14">
        <div v-for="(item, index) in students.data" :key="index" class="intro-y col-span-12 md:col-span-6">
          <div class="box">
            <div class="
                  flex flex-col
                  lg:flex-row
                  items-center
                  p-5
                  border-b border-gray-200
                  dark:border-dark-5
                ">
              <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                <img alt="Smarthub" class="rounded-full" :src="
                  item.image
                    ? item.image
                    : require('@/assets/images/profile-1.jpg')
                " />
              </div>
              <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                <router-link :to="{ name: 'users-show', params: { id: item.id } }" class="font-medium">{{ item.fullname
                }}</router-link>
                <div class="text-gray-600 text-xs mt-0.5">
                  {{ item.email }}
                </div>
              </div>
              <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                <button class="btn btn-success py-1 px-2 mr-2" v-if="!item.pivot.is_active"
                  @click="onAcceptStudent(item.id)">
                  Принять
                </button>
                <button class="btn btn-danger py-1 px-2 mr-2" @click="$refs.deleteModal.openModal(item.id)">
                  <span v-if="!item.pivot.is_active">
                    Отклонить
                  </span>
                  <span v-else>Удалить</span>
                </button>
                <router-link :to="`/users/show/${item.id}`" class="btn btn-primary py-1 px-2 mr-2">Профиль</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="
            intro-y
            text-lg
            font-medium
            text-gray-600 text-center
            h-72
            flex
            items-center
            justify-center
          ">
        Извините ничего не найдено !
      </div>
      <!-- END: Users Layout -->
      <AlertRemove ref="deleteModal" :onDelete="onDelete" />

      <Pagination v-if="students" :per_page="students.per_page" :total="students.total" :current-page="currentPage" />
    </div>
  </div>
</template>

<script>
import AsideModules from '@/components/aside/Modules'

export default {
  components: {
    AsideModules
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    },
    slug() {
      return this.$route.params.slug
    },
    course() {
      return this.$store.state.courses.course
    },
    students() {
      return this.$store.state.students.students
    }
  },
  watch: {
    currentPage() {
      this.pagination(this.currentPage)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('courses/getCourse', this.slug)
      this.$store.dispatch('students/getStudents', { course_slug: this.slug })
    },
    pagination(page) {
      this.$store.dispatch('students/getStudents', {
        course_slug: this.slug,
        pages: page
      })
    },
    onAcceptStudent(userId) {
      this.$api.put(`/dashboard/course/${this.slug}/accept-student`, {
        user_id: userId
      })
      this.init()
    },
    onDelete(deletingItem) {
      this.$api.put(`/dashboard/course/${this.slug}/detach`, {
        user_id: deletingItem
      })
      this.init()
    }
  }
}
</script>
